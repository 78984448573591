// App.js
import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import { AuthProvider } from "./AuthContext"; // Adjust the import path as necessary
import keepme from "./assets/buttons/svgKeepMe/KeepMe_logo_fullcolor_rgb.svg";
import { useTranslation } from "react-i18next";

const PaymentFailure = lazy(
  () => import("./components/ProfilePageComp/Pricing/PaymentFailure")
);
const PaymentSuccess = lazy(
  () => import("./components/ProfilePageComp/Pricing/PaymentSuccess")
);
const Pricing = lazy(
  () => import("./components/ProfilePageComp/Pricing/Pricing")
);
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const BagOlymp = lazy(() => import("./components/BagOlymp/BagOlymp"));
const CompanyRegistered = lazy(() => import("./components/CompanyRegistered"));
const Homepage = lazy(() => import("./components/Homepage"));
const SignIn = lazy(() => import("./components/WelcomeComp/SignIn"));
const SignUp = lazy(() => import("./components/WelcomeComp/SignUp"));
const ProfilePage = lazy(() => import("./components/ProfilePage"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const SetPassword = lazy(() => import("./components/SetPassword"));
const RegisterCompany = lazy(
  () => import("./components/WelcomeComp/RegisterCompany")
);
const JoinCompany = lazy(() => import("./components/WelcomeComp/JoinCompany"));
const DisplayTemplate = lazy(
  () => import("./components/ProfilePageComp/Templates/DisplayTemplate")
);
const ProfileContextProvider = lazy(
  () => import("./components/Context/ProfileContext")
);
const NoCompanyReg = lazy(
  () => import("./components/WelcomeComp/NoCompanyReg")
);
const PasswordChange = lazy(
  () => import("./components/WelcomeComp/PasswordChange")
);
const ActivateAccount = lazy(
  () => import("./components/WelcomeComp/ActivationSuccess")
);
const RegistrationSuccess = lazy(
  () => import("./components/WelcomeComp/RegistrationSuccess")
);
const PasswordReset = lazy(
  () => import("./components/WelcomeComp/PasswordReset")
);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Explicitly set language to English on initial load
    i18n.changeLanguage("en");
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Suspense
          fallback={
            <div className="app-suspense">
              <img className="app-suspense-img" src={keepme} alt="Keep Me" />
            </div>
          }
        >
          <ProfileContextProvider>
            <div className="App">
              <Routes>
                <Route path="/" element={<Homepage keepme={keepme} />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/registration-success"
                  element={<RegistrationSuccess />}
                />
                <Route path="/activate/:token" element={<ActivateAccount />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route
                  path="/change-password/:token"
                  element={<PasswordChange />}
                />
                <Route
                  path="/userassign"
                  element={
                    <CompanyRegistered>
                      <NoCompanyReg />
                    </CompanyRegistered>
                  }
                />
                <Route
                  path="/register-company"
                  element={
                    <CompanyRegistered>
                      <PrivateRoute>
                        <RegisterCompany />
                      </PrivateRoute>
                    </CompanyRegistered>
                  }
                />
                <Route
                  path="/join-company"
                  element={
                    <CompanyRegistered>
                      <PrivateRoute>
                        <JoinCompany />
                      </PrivateRoute>
                    </CompanyRegistered>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/card/:companyName/:username"
                  element={<DisplayTemplate />}
                />
                <Route path="/bag/olymp" element={<BagOlymp />} />
                <Route path="/set-password/:token" element={<SetPassword />} />
                <Route
                  path="/pricing"
                  element={
                    <PrivateRoute>
                      <Pricing />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/payment-success"
                  element={
                    <PrivateRoute>
                      <PaymentSuccess />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/payment-failure"
                  element={
                    <PrivateRoute>
                      <PaymentFailure />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </ProfileContextProvider>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
